import api from '@/services/api';

const url = 'provider';

const getProvider = () => api.get(`${url}/read-provider`);

const createProvider = (Provider) => api.post(`${url}/create-provider`, Provider);

const updateProvider = (Provider) => api.put(`${url}/update-provider`, Provider);

const deleteProvider = (id) => api.delete(`${url}/delete-provider/${id}`);

export default {
  getProvider, createProvider, updateProvider, deleteProvider,
};
