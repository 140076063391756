<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="provider"
      :search="search"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Credenciados</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          />

          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              @click="prepareToDelete(item)"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
      <template #[`item.active`]="{ item }">
        <v-checkbox
          :input-value="item.active"
          class="primary--text"
          value
          disabled
        />
      </template>
      <template #[`item.initialDate`]="{ item }">
        <p>{{ item.initialDate | parseOnlyDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <form-provider
        v-if="dialog"
        :populate-with="providerToEdit"
        @provider-added="addProvider"
        @provider-edited="editProvider"
        @close-dialog="closeDialog"
      />
    </v-dialog>
    <delete-confirmation
      :visible="dialogDeleteConfirmation"
      @cancel="dialogDeleteConfirmation = false"
      @confirm="deleteItem()"
    />
  </div>
</template>

<script>
import ProviderService from '../../../services/provider.service';
import DeleteConfirmation from '../../../components/shared/DeleteConfirmation';

export default {
  name: 'Provider',
  components: {
    'form-provider': () => import('./Form'),
    DeleteConfirmation,
  },

  data: () => ({
    headers: [
      {
        text: 'Nome',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'CRM',
        sortable: false,
        value: 'crm',
      },
      {
        text: 'Especialidade',
        sortable: false,
        value: 'specialty',
      },
      { text: 'Ações', value: 'actions', sortable: false },
    ],

    provider: [],
    search: '',
    dialog: false,
    providerToEdit: {},
    dialogDeleteConfirmation: false,
  }),

  async created() {
    const data = await ProviderService.getProvider();
    this.provider = data;
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.providerToEdit = {};
    },

    editItem(item) {
      this.providerToEdit = item;
      this.dialog = true;
    },

    prepareToDelete(item) {
      this.dialogDeleteConfirmation = true;
      this.deleteItemID = item.id;
    },

    async deleteItem() {
      try {
        await ProviderService.deleteProvider(this.deleteItemID);
        const index = this.provider.findIndex((listProvider) => listProvider.id === this.deleteItemID);
        this.provider.splice(index, 1);
        this.dialogDeleteConfirmation = false;
        this.$toast.success('Credenciado excluído com sucesso');
      } catch (e) {
        this.$handleHttpError(e);
      }
    },

    addProvider(provider) {
      this.provider.push(provider);
    },

    editProvider(provider) {
      const index = this.provider.findIndex((listProvider) => listProvider.id === provider.id);
      this.provider[index] = provider;
    },
  },
};
</script>
